@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&family=PT+Mono&display=swap');

body {
  margin: 0;
  background: #f9f9fc;

  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.rbc-overlay-header {
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rbc-overlay {
  padding: 5px 10px;
}

.rbc-event.rbc-event-continues-after {
  background-color: inherit;
}

.rbc-event.rbc-event-continues-after.rbc-selected {
  border-radius: 10px;
  background-color: inherit;
}

.sigCanvas {
  border-radius: 10px;
  border: 1px solid var(--icon, #848a9b);
  background-color: #fff;
}
